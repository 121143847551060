import React from 'react'
import PropTypes from 'prop-types';
import parse from 'html-react-parser'
import { Container } from 'react-bootstrap'
import gplayImage from '../../../../assets//images/icons/aeroland-button-google-play.jpg'
import appImage from '../../../../assets//images/icons/aeroland-button-app-store.jpg'
import { Row, Col } from '../../../../components/ui/wrapper'
import Text from '../../../../components/ui/text'
import Anchor from '../../../../components/ui/anchor'
import Button from '../../../../components/ui/button'
import Heading from '../../../../components/ui/heading'
import Social, { SocialLink } from '../../../../components/ui/social'
import {
    FooterWrap,
    FooterTop,
    FooterWidget,
    LogoWidget,
    TextWidget,
    FooterWidgetList,
    FooterBottom
} from './footer.style'

const Footer = ({ logo, siteInfo, footerMenu, copyrightStyle, ...props }) => {    
    const { phone, email, address, website } = siteInfo.siteMetadata.contact;
    const { copyright } = siteInfo.siteMetadata;
    const { facebook, twitter, instagram, linkedin } = siteInfo.siteMetadata.social;
    return (
        <FooterWrap {...props}>
            <FooterTop>
                <Container>
                    <Row>
                        <Col lg={4} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                                <LogoWidget>
                                    <img src={logo} alt="Logo" />
                                </LogoWidget>
                                <TextWidget>
                                    {address && <Text mb="10px">{address}</Text>}
                                    {email && <Text mb="10px"><Anchor path={`mailto:${email}`} color="textColor" hoverstyle="2">{email}</Anchor></Text>}
                                    {phone && <Text mb="10px"><Anchor path={`tel:${phone}`} fontWeight="800" color="#333" hoverstyle="2">{phone}</Anchor></Text>}
                                    {website && <Text mb="10px"><Anchor path={website} hoverstyle="2">{website}</Anchor></Text>}
                                </TextWidget>
                            </FooterWidget>
                        </Col>
                        {footerMenu.edges[0] && <Col lg={2} md={4} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                                <Heading as="h6" mt="-3px" mb="20px">{footerMenu.edges[0].node.text}</Heading>
                                <FooterWidgetList>
                                {footerMenu.edges[0].node.submenu.map(item => (
                                    <li key={item.id}><Anchor path={item.link} color="textColor" hoverstyle="2">{item.text}</Anchor></li>                                        
                                    ))}                                    
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>}
                        {footerMenu.edges[1] && <Col lg={2} md={4} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '27px' } }}>
                                <Heading as="h6" mt="-3px" mb="20px">{footerMenu.edges[1].node.text}</Heading>
                                <FooterWidgetList>
                                {footerMenu.edges[1].node.submenu.map(item => (
                                    <li key={item.id}><Anchor path={item.link} color="textColor" hoverstyle="2">{item.text}</Anchor></li>                                        
                                    ))}    
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>}
                        {footerMenu.edges[2] && <Col lg={2} md={4} sm={6}>
                            <FooterWidget>
                                <Heading as="h6" mt="-3px" mb="20px">{footerMenu.edges[2].node.text}</Heading>
                                <FooterWidgetList>
                                {footerMenu.edges[2].node.submenu.map(item => (
                                    <li key={item.id}><Anchor path={item.link} color="textColor" hoverstyle="2">{item.text}</Anchor></li>                                        
                                    ))}  
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>}
                        {/*<Col lg={2} md={4} sm={6}>
                            <FooterWidget mt='50px' responsive={{ small: { mt: '34px' } }}>
                                <FooterWidgetList>
                                    <li>
                                        <Button path="/" to="/" imgbutton="true" shadow="true"><img src={gplayImage} alt="Google Play" /></Button>
                                    </li>
                                    <li>
                                        <Button path="/" to="/" imgbutton="true" shadow="true"><img src={appImage} alt="App Store" /></Button>
                                    </li>
                                </FooterWidgetList>
                            </FooterWidget>
                                </Col>*/}
                    </Row>
                </Container>
            </FooterTop>
            <FooterBottom>
                <Container>
                    <Row className="align-items-center">
                        <Col md={6} className="text-center text-md-left">
                            {copyright && <Text {...copyrightStyle}>&copy; {new Date().getFullYear()} {parse(copyright)}</Text>}
                        </Col>
                        <Col md={6} className="text-center text-md-right">
                            <Social space="8px" tooltip={true} shape="rounded" varient="outlined">
                                {twitter && (
                                    <SocialLink
                                        path={twitter}
                                        title="Twitter">
                                        <i className="fab fa-twitter link-icon"></i>
                                    </SocialLink>
                                )}
                                {facebook && (
                                    <SocialLink
                                        path={facebook}
                                        title="Facebook">
                                        <i className="fab fa-facebook-f link-icon"></i>
                                    </SocialLink>
                                )}
                                {instagram && (
                                    <SocialLink
                                        path={instagram}
                                        title="Instagram">
                                        <i className="fab fa-instagram link-icon"></i>
                                    </SocialLink>
                                )}
                                {linkedin && (
                                    <SocialLink
                                        path={linkedin}
                                        title="Linkedin">
                                        <i className="fab fa-linkedin link-icon"></i>
                                    </SocialLink>
                                )}
                            </Social>
                        </Col>
                    </Row>
                </Container>
            </FooterBottom>
        </FooterWrap>
    )
}

Footer.propTypes = {
    bgcolor: PropTypes.string,
    reveal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

Footer.defaultProps = {
    bgcolor: '#F8F8F8',
    reveal: 'false',
    copyrightStyle: {
        responsive: {
            small: {
                pb: '15px'
            }
        }
    }
};

export default Footer
