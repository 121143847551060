import React from 'react'
import PropTypes from "prop-types"
import {ThemeProvider} from 'styled-components';
import {theme} from '../theme'
import '../assets/fonts/cerebrisans/cerebrisans.css'
import '../assets/fonts/font-awesome/font-awesome.css'
import '../assets/css/bootstrap.css';
import {GlobalStyle} from '../assets/css/main-style'
import ScrollToTop from '../components/ui/scroll-to-top'
import Transition from '../components/transition'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import SEO from '../components/seo'

const Layout = ({children, location, menuData, footerMenu, headline, site, browserTitle, lightLogo, darkLogo, metaImage }) => {
    return (
        <ThemeProvider theme={theme}>
            <Transition location={location}>
                <div className="wrapper">
                    <GlobalStyle/>
                    <SEO site={site} title={browserTitle} image={metaImage} /> 
                    <Header menuData={menuData} headline={headline} lightLogo={lightLogo} darkLogo={darkLogo} />
                    {children}
                    <Footer logo={darkLogo} siteInfo={site} footerMenu={footerMenu} />
                    <ScrollToTop/>
                </div>
            </Transition>
        </ThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
  
export default Layout